:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
.feature-box {
  overflow: hidden;
}

.cart-table thead th {
  background-color: #65bef6;
  color: #fff;
  font-weight: bold;
  border: none;
}
.cart-table tbody td {
  vertical-align: middle;
  border-color: #d5efff;
}
.cart-table tfoot td {
  background-color: #f7f7f7;
  color: #4797eb;
  border: none;
}
.cart-table .totals td {
  background-color: #d5efff;
  color: #4c4d52;
  padding-top: 1em;
  padding-bottom: 1em;
}
.cart-table h4 {
  color: inherit;
}
.cart-table .cart-item-description .highlight-box {
  display: flex;
  justify-content: space-between;
}
.cart-table .cart-item-description p {
  margin: 0;
}
.cart-table .cart-item-description .no-thanks {
  white-space: nowrap;
  margin-left: 1rem;
}
.cart-table .text-large {
  font-weight: normal;
}
.cart-table .delete-link {
  opacity: 0.5;
  position: relative;
  top: -0.15rem;
}
.cart-table .delete-link:hover {
  opacity: 1;
}
.cart-table .discount-text {
  font-size: 0.6em;
  line-height: 1.2;
  display: block;
  color: #00c3a9;
  white-space: nowrap;
}
.cart-table label {
  display: none;
}

@media (max-width: 900px) {
  div[role=main] table.cart-table tbody tr,
  div[role=main] table.cart-table tfoot tr {
    display: flex;
    flex-wrap: wrap;
  }
  div[role=main] table.cart-table tbody tr:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  div[role=main] table.cart-table tbody td {
    border-bottom: none;
  }
  div[role=main] table.cart-table .cart-item-icon {
    flex: 0 1 20%;
  }
  div[role=main] table.cart-table .cart-item-description {
    flex: 1 1 80%;
  }
  div[role=main] table.cart-table .cart-item-description p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
  div[role=main] table.cart-table .cart-item-qty {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div[role=main] table.cart-table label {
    display: inline-block;
    flex: 1 1 20%;
  }
  div[role=main] table.cart-table input[type=text] {
    flex: 1 1 76%;
  }
  div[role=main] table.cart-table .cart-item-price {
    padding-left: 20%;
  }
  div[role=main] table.cart-table .cart-item-actions {
    flex: 1 1 100%;
    text-align: center;
    padding-right: 1rem;
  }
  div[role=main] table.cart-table tfoot {
    display: block;
  }
  div[role=main] table.cart-table tfoot tr {
    border: none;
    padding: 0;
    margin: 0;
  }
  div[role=main] table.cart-table tfoot td {
    flex: 1;
  }
}
h1 .order-number {
  color: #65bef6;
  font-size: 0.75em;
}

.assign-course {
  margin-bottom: 4em;
}
.assign-course header {
  background-color: #59C3AB;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  flex-wrap: wrap;
}
@media (min-width: 600px) {
  .assign-course header {
    flex-wrap: nowrap;
  }
}
.assign-course header img {
  flex: 1 1 10%;
  max-width: 96px;
}
.assign-course header h2 {
  color: inherit;
  font-size: 1.2rem;
}
@media (min-width: 600px) {
  .assign-course header h2 {
    font-size: 1.5rem;
  }
}
.assign-course header strong {
  display: block;
  text-align: center;
  margin: -1em 0 1em auto;
  margin-left: auto;
  margin-top: -1em;
  margin-bottom: 1em;
  width: 100%;
}
@media (min-width: 600px) {
  .assign-course header strong {
    font-size: 1.2em;
    width: auto;
    margin: 0 0 0 auto;
  }
}
.assign-course form {
  margin: 1rem;
  padding: 0 0 0 5%;
}
@media (min-width: 600px) {
  .assign-course form {
    padding: 0 10%;
  }
}

.assign-list {
  counter-reset: assign-counter;
}
.assign-list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  counter-increment: assign-counter;
  position: relative;
  margin-bottom: 2em;
}
@media (min-width: 600px) {
  .assign-list li {
    flex-wrap: nowrap;
    margin-bottom: 1em;
  }
}
.assign-list li::before {
  content: counter(assign-counter) ".";
  display: inline-block;
  width: 1.5em;
  top: 0.5em;
  left: -2em;
  text-align: right;
  position: absolute;
}
.assign-list li > * {
  margin-right: 0.5rem;
  flex: 1 1 45%;
}
@media (min-width: 600px) {
  .assign-list li:first-child label {
    display: block;
  }
  .assign-list li:first-child .assign-self, .assign-list li:first-child::before {
    margin-top: 1.9em;
  }
}
.assign-list label {
  display: none;
  color: #65bef6;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0;
  padding: 0 0 0.5rem;
  float: none;
}
.assign-list label,
.assign-list input[type=text],
.assign-list input[type=email] {
  width: 100%;
  margin-bottom: 0.5em;
}
@media (min-width: 600px) {
  .assign-list label,
  .assign-list input[type=text],
  .assign-list input[type=email] {
    margin-bottom: 0;
  }
}
.assign-list input[type=text],
.assign-list input[type=email] {
  font-size: 0.85rem;
}
@media (min-width: 600px) {
  .assign-list input[type=text],
  .assign-list input[type=email] {
    font-size: 1rem;
  }
}
.assign-list input[type=text]::placeholder,
.assign-list input[type=email]::placeholder {
  color: #aaa;
  font-style: italic;
}
.assign-list .assign-self {
  white-space: nowrap;
}
@media (max-width: 600px) {
  .assign-list .assign-self {
    flex: 1 1 100%;
  }
}

.copy-link {
  text-decoration: none;
  display: inline-block;
  margin: 0 0.5em;
}
.copy-link:hover {
  text-decoration: underline;
}
.copy-link .icon-copy {
  position: relative;
  top: 0.2em;
}

.steps,
.steps > ol,
.steps > ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  border-top: 3px solid #65bef6;
  font-weight: bold;
  font-size: 0.8em;
  text-align: center;
  list-style: none;
}
.steps > *,
.steps > ol > *,
.steps > ul > * {
  flex: 1 0;
}

.steps-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -0.75rem;
  margin: 0;
  color: #abb4b9;
}
.steps-step:first-child {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
}
.steps-step:last-child {
  background-image: linear-gradient(to right, transparent 50%, #fff 50%);
}
.steps-step.active {
  color: #65bef6;
}
.steps-step::before {
  content: "";
  border: 3px solid #65bef6;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps-step.complete::before {
  content: url('data:image/svg+xml;utf8,<svg width="14" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 10.973c.2 0 .365-.073.492-.22l8.067-8.038A.666.666 0 0014 2.223c0-.2-.064-.365-.191-.493L12.797.746a.61.61 0 00-.479-.219.695.695 0 00-.506.22L5.25 7.308 2.187 4.246a.695.695 0 00-.505-.219.61.61 0 00-.479.22L.191 5.23A.666.666 0 000 5.723c0 .2.064.364.191.492l4.567 4.539a.624.624 0 00.492.219z" fill="white"/></svg>');
}
.steps-step.complete::before, .steps-step.active::before {
  background-color: #65bef6;
}