@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../base/variables";
@import "../utils/mixins";

// Checkout Pages
// ----------------------------------------------------------------

.feature-box {
  overflow: hidden;
}

.cart-table {

  thead th {
    background-color: palette(blue, light);
    color: #fff;
    font-weight: bold;
    border: none;
  }

  tbody td {
    vertical-align: middle;
    border-color: palette(blue, x-light);
  }

  tfoot td {
    background-color: #f7f7f7;
    color: palette(blue);
    border: none;
  }

  .totals td {
    background-color: palette(blue, x-light);
    color: $base-font-color;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  h4 {
    color: inherit;
  }

  .cart-item-description .highlight-box {
    display: flex;
    justify-content: space-between;
  }
  .cart-item-description p {
    margin: 0;
  }
  .cart-item-description .no-thanks {
    white-space: nowrap;
    margin-left: 1rem;
  }

  .text-large {
    font-weight: normal;
  }

  .delete-link {
    opacity: .5;
    position: relative;
    top: -0.15rem;
    &:hover { opacity: 1; }
  }

  .discount-text {
    font-size: 0.6em;
    line-height: 1.2;
    display: block;
    color: palette(teal);
    white-space: nowrap;
  }

  label { display: none; }
  
}

// Smaller-screen layout
@include breakpoint(max-width $lg) {
  div[role=main] table.cart-table {
    tbody tr,
    tfoot tr {
      display: flex;
      flex-wrap: wrap;
    }
    tbody tr:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    tbody td {
      border-bottom: none;
    }
    .cart-item-icon {
      flex: 0 1 20%;
    }
    .cart-item-description {
      flex: 1 1 80%;
    }
    .cart-item-description p {
      font-size: .7rem;
      margin-bottom: 0;
    }
    .cart-item-qty {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    label {
      display: inline-block;
      flex: 1 1 20%;
    }
    input[type="text"] {
      flex: 1 1 76%;
    }
    .cart-item-price {
      padding-left: 20%;
    }
    .cart-item-actions {
      flex: 1 1 100%;
      text-align: center;
      padding-right: 1rem;
    }

    tfoot {
      display: block;
    }
    tfoot tr {
      border: none;
      padding: 0;
      margin: 0;
    }
    tfoot td {
      flex: 1;
    }
  }
}


// Assign Courses
// ----------------------------------------------------------------

h1 .order-number {
  color: palette(blue, light);
  font-size: .75em;
}

.assign-course {
  margin-bottom: 4em;

  header {
    background-color: #59C3AB;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    flex-wrap: wrap;

    @include breakpoint($med) {
      flex-wrap: nowrap;
    }

    img {
      flex: 1 1 10%;
      max-width: 96px;
    }
    h2 {
      color: inherit;
      font-size: 1.2rem;

      @include breakpoint($med) {
        font-size: 1.5rem;
      }
    }
    strong {
      display: block;
      text-align: center;
      margin: -1em 0 1em auto;
      margin-left: auto;
      margin-top: -1em;
      margin-bottom: 1em;
      width: 100%;

      @include breakpoint($med) {
        font-size: 1.2em;
        width: auto;
        margin: 0 0 0 auto;
      }
    }
  }

  form {
    margin: 1rem;
    padding: 0 0 0 5%;

    @include breakpoint($med) {
      padding: 0 10%;
    }
  }
}

.assign-list {
  counter-reset: assign-counter;

  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    counter-increment: assign-counter;
    position: relative;
    margin-bottom: 2em;

    @include breakpoint($med) {
      flex-wrap: nowrap;
      margin-bottom: 1em;
    }

    &::before {
      content: counter(assign-counter) '.';
      display: inline-block;
      width: 1.5em;
      top: .5em;
      left: -2em;
      text-align: right;
      position: absolute;
    }

    > * {
      margin-right: .5rem;
      flex: 1 1 45%;
    }

    &:first-child {
      @include breakpoint($med) {
        label { display: block; }
        .assign-self,
        &::before { margin-top: 1.9em; }
      }
    }
  }
  label {
    display: none;
    color: palette(blue, light);
    text-transform: uppercase;
    font-size: .8em;
    margin: 0;
    padding: 0 0 .5rem;
    float: none;
  }
  label,
  input[type=text],
  input[type=email] {
    width: 100%;
    margin-bottom: .5em;

    @include breakpoint($med) {
      margin-bottom: 0;
    }
  }

  input[type=text],
  input[type=email] {
    font-size: .85rem;

    @include breakpoint($med) {
      font-size: 1rem;
    }

    &::placeholder {
      color: #aaa;
      font-style: italic;
    }
  }

  .assign-self {
    white-space: nowrap;

    @include breakpoint(max-width $med) {
      flex: 1 1 100%;
    }
  }
}

.copy-link {
  text-decoration: none;
  display: inline-block;
  margin: 0 .5em;

  &:hover { text-decoration: underline; }

  .icon-copy {
    position: relative;
    top: .2em;
  }
}


// Steps
// ----------------------------------------------------------------
$steps-size: 1rem;
$steps-color: palette(blue, light);

.steps,
.steps > ol,
.steps > ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  border-top: 3px solid $steps-color;
  font-weight: bold;
  font-size: .8em;
  text-align: center;
  list-style: none;

  > * {
    flex: 1 0;
  }
}
.steps-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -.75rem;
  margin: 0;
  color: $quiet-font-color;

  // Obscure the connecting line with white background
  // note: doesn't work if steps is on non-white background
  &:first-child {
    background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  }
  &:last-child {
    background-image: linear-gradient(to right, transparent 50%, #fff 50%);
  }

  &.active {
    color: $steps-color;
  }

  &::before {
    content: '';
    border: 3px solid $steps-color;
    background-color: #fff;
    border-radius: 50%;
    display: block;
    width: $steps-size;
    height: $steps-size;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.complete::before {
    content: url('data:image/svg+xml;utf8,<svg width="14" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 10.973c.2 0 .365-.073.492-.22l8.067-8.038A.666.666 0 0014 2.223c0-.2-.064-.365-.191-.493L12.797.746a.61.61 0 00-.479-.219.695.695 0 00-.506.22L5.25 7.308 2.187 4.246a.695.695 0 00-.505-.219.61.61 0 00-.479.22L.191 5.23A.666.666 0 000 5.723c0 .2.064.364.191.492l4.567 4.539a.624.624 0 00.492.219z" fill="white"/></svg>');
  }
  &.complete::before,
  &.active::before {
    background-color: $steps-color;
  }
}

